import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import BgzlCardUtil ,{IBgzlCardDataObj} from './bgzlCardUtil';
export default defineComponent({
    name:'BgzlCard',
    title:'报关资料',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IBgzlCardDataObj=reactive<IBgzlCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/bgzl')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {},
            otherParams:{
                content:{
                    htxxd:[],//合同信息单
                    xdxxd:[],//箱单信息单
                    fpxxd:[],//发票信息单
                }
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new BgzlCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        const buildUploadInitParams=computed(()=>{
            return (params:any) => {
                return {
                    belongMaxId:dataObj.form.childOrderId,
                    fileSize:80,
                    accept:'.pdf',
                    showFileList:true,
                    disabled:dataObj.disabled,
                    uploadType:params.uploadType,
                    type:params.type,
                    title:params.title
                }
            }
        })
        //下载附件
        const handlePreview=(file:any)=>{
            utils.Tools.configBox({
                message:'即将下载该附件，是否继续？',
                sureFn: ()=>{
                    let params={id:file.id,fileName:file.name};
                    utils.Api.downLoad(params)
                }
            });
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            dataObj.form.childOrderId=res.data.childOrderId;
            if(!res.data.id){
                addOrLoad='/add'
            }else{
                dataObj.disabled=true;
            }
            //先清空
            dataObj.otherParams.content={
                htxxd:[],
                xdxxd:[],
                fpxxd:[]
            }
            res.data.list.forEach((item:any)=>{
                let flag:boolean=false;
                switch (item.type) {
                    case 0://合同信息单
                        if(dataObj.form.yxht && dataObj.form.yxht.indexOf(item.id)!=-1)flag=true;
                        let index0=dataObj.otherParams.content.htxxd.length+1;
                        dataObj.otherParams.content.htxxd.push({id:item.id,name:'报关合同-'+index0+'.pdf',flag: flag});
                        break;
                    case 1://箱单信息单
                        if(dataObj.form.yxxd && dataObj.form.yxxd.indexOf(item.id)!=-1)flag=true;
                        let index1=dataObj.otherParams.content.xdxxd.length+1;
                        dataObj.otherParams.content.xdxxd.push({id:item.id,name:'箱单-'+index1+'.pdf',flag: flag});
                        break;
                    case 2://发票信息单
                        if(dataObj.form.yxfp && dataObj.form.yxfp.indexOf(item.id)!=-1)flag=true;
                        let index2=dataObj.otherParams.content.fpxxd.length+1;
                        dataObj.otherParams.content.fpxxd.push({id:item.id,name:'发票-'+index2+'.pdf',flag: flag});
                        break;
                }
            })
            nextTick(()=>{
                dataObj.refMap.get('ckxkz').uploadParams.belongMaxId=dataObj.form.childOrderId;
                dataObj.refMap.get('bgd').uploadParams.belongMaxId=dataObj.form.childOrderId;
                dataObj.refMap.get('fxtzs').uploadParams.belongMaxId=dataObj.form.childOrderId;
            })
        }
        //加载pi参数
        const setParam=(engineInst:any)=>{
            return {cardFrom:engineInst.engineParams.cardFrom,childOrderId:engineInst.engineParams.id,type:engineInst.engineParams.type};
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            dataObj.form.yxht='';
            dataObj.form.yxxd='';
            dataObj.form.yxfp='';
            dataObj.otherParams.content.htxxd.forEach((item:any)=>{
                if(item.flag)dataObj.form.yxht=dataObj.form.yxht+item.id+','
            })
            dataObj.otherParams.content.xdxxd.forEach((item:any)=>{
                if(item.flag)dataObj.form.yxxd=dataObj.form.yxxd+item.id+','
            })
            dataObj.otherParams.content.fpxxd.forEach((item:any)=>{
                if(item.flag)dataObj.form.yxfp=dataObj.form.yxfp+item.id+','
            })
            if(dataObj.form.yxht.endsWith(','))dataObj.form.yxht=dataObj.form.yxht.substr(0,dataObj.form.yxht.length-1);
            if(dataObj.form.yxxd.endsWith(','))dataObj.form.yxxd=dataObj.form.yxxd.substr(0,dataObj.form.yxxd.length-1);
            if(dataObj.form.yxfp.endsWith(','))dataObj.form.yxfp=dataObj.form.yxfp.substr(0,dataObj.form.yxfp.length-1);
            if(!dataObj.form.yxht || !dataObj.form.yxxd || !dataObj.form.yxfp){
                proxy.$message({type:'error',message:'每项信息单各自至少勾选一个才能保存'});
                return false;
            }
            if(dataObj.refMap.get('bgd').fileList.length==0 || dataObj.refMap.get('fxtzs').fileList.length==0) {
                proxy.$message({type:'error',message:'至少上传一份报关单和放行通知书才能保存'});
                return false;
            }
            return true;
        }
        //新增信息单
        const addXxd=async (type:number)=>{
            switch (type) {
                case 0:await dataObj.utilInst.openForm('',dataObj.form.childOrderId,0,'合同信息单');break;//新增合同信息单
                case 1:await dataObj.utilInst.openForm('',dataObj.form.childOrderId,1,'箱单信息单');break;//新增箱单信息单
                case 2:await dataObj.utilInst.openForm('',dataObj.form.childOrderId,2,'发票信息单');break;//新增发票信息单
            }
        }
        //查看pdf
        const pdfHandler=async (id:string,type:number)=>{
            switch (type) {
                case 0:await dataObj.utilInst.openPdf(id,dataObj.form.childOrderId,0,'合同信息单');break;//新增合同信息单
                case 1:await dataObj.utilInst.openPdf(id,dataObj.form.childOrderId,1,'箱单信息单');break;//新增箱单信息单
                case 2:await dataObj.utilInst.openPdf(id,dataObj.form.childOrderId,2,'发票信息单');break;//新增发票信息单
            }
        }
        return{
            ...toRefs(dataObj),proxy,buildUploadInitParams,handlePreview,beforeOpen,setParam,beforeSaveHandler
            ,addXxd,pdfHandler
         
        }
    }
});